<template>
  <BasePageContent>
    <BasePageHeader
      :sectiontitle="
        (uuid || manufacturer) && !Util.isEmpty(Manufacturer)
          ? 'EDIT MANUFACTURER'
          : ''
      "
      :title="
        (uuid || manufacturer) && !Util.isEmpty(Manufacturer)
          ? Manufacturer.manufacturer_name
          : 'New Manufacturer'
      "
      :description="
        (uuid || manufacturer) && !Util.isEmpty(Manufacturer)
          ? Manufacturer.email
          : ''
      "
    >
      <ul class="nk-block-tools g-1">
        <li v-if="(uuid || manufacturer) && !Util.isEmpty(Manufacturer)">
          <div class="drodown">
            <a
              href="#"
              class="dropdown-toggle btn btn-white btn-dim btn-outline-light"
              data-toggle="dropdown"
            >
              <em class="icon ni ni-more-h"></em
            ></a>
            <div class="dropdown-menu dropdown-menu-right">
              <ul class="link-list-opt no-bdr">
                <li>
                  <a title="Disable Manufacturer" href="#">
                    <em class="dd-indc icon ni ni-copy"></em>
                    <span>Disable Manufacturer</span>
                  </a>
                </li>
                <li>
                  <a
                    title="Delete Manufacturer"
                    class="w3-text-red w3-hover-text-black"
                    href="#"
                  >
                    <em class="dd-indc icon ni ni-trash"></em>
                    <span> Delete Manufacturer</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li class="nk-block-tools-opt">
          <a
            class="btn w3-theme w3-hover-theme btn-dim btn-outline-light"
            title="Save Changes"
            href="#"
            @click.prevent="SaveManufacturer"
          >
            <em class="icon ni ni-save"></em>
            <span>Save</span>
          </a>
        </li>
        <li class="nk-block-tools-opt">
          <a href="#" @click.prevent="OnCancel"
            ><em class="icon ni ni-cross size36"></em
          ></a>
        </li>
      </ul>
    </BasePageHeader>
    <div class="card-inner card-inner-md">
      <BaseIndicator
        text="Fetching manufacturer detail..."
        class="my-4"
        v-if="Util.fetching('getManufacturer')"
        :size="50"
      />
      <template v-else>
        <template v-if="!uuid || (uuid && !Util.isEmpty(Manufacturer))">
          <form :id="ID" @submit.prevent="SaveManufacturer">
            <div v-if="tabbed" id="tabs" class="mb-4 py-0 w3-border-bottom">
              <button
                v-for="view in Views"
                :key="view"
                @click.prevent="SwitchView(view)"
                class="w3-button px-2 pt-3 w3-hover-transparent"
                :class="[
                  ActiveView === view
                    ? 'pb-1 w3-bottombar w3-border-theme'
                    : ' pb-2',
                ]"
              >
                <i
                  class="w3-text-theme w3-hover-text-theme"
                  :class="ViewsIcons[view]"
                />
                <span class="w3-hide-small ml-1">{{ view }}</span>
              </button>
            </div>
            <div
              v-show="IsActiveView('Contact')"
              class="basicInfo mt-3 w3-animate-opacity"
              :class="[!Tabbed ? 'w3-border-bottom' : '']"
            >
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="manufacturer_name">Manufacturer name</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="manufacturer_name"
                    v-model="Manufacturer.manufacturer_name"
                    hint="Name of a business or person."
                  />
                </div>
              </div>
              <div class="row g-2">
                <div class="col-md-4">
                  <h6>Primary Contact</h6>
                </div>
                <div class="col-md-8 mb-2">
                  <div class="row g-2">
                    <div class="col-md-6 mb-2">
                      <BaseInput
                        id="firstname"
                        v-model="Manufacturer.firstname"
                        label="First Name"
                        type="text"
                      />
                    </div>
                    <div class="col-md-6 mb-2">
                      <BaseInput
                        id="lastname"
                        v-model="Manufacturer.lastname"
                        label="Last Name"
                        type="text"
                      />
                    </div>
                    <div class="col-md-12 mb-2">
                      <BaseInput
                        id="email"
                        v-model="Manufacturer.email"
                        label="Email"
                        type="email"
                      />
                    </div>
                    <div class="col-md-6 mb-2">
                      <BaseInput
                        id="telephone"
                        v-model="Manufacturer.telephone"
                        label="Telephone"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="IsActiveView('Address')"
              class="addressInfo mt-3 w3-animate-opacity"
              :class="[!Tabbed ? 'w3-border-bottom' : '']"
            >
              <div class="row g-2">
                <div class="col-md-4">
                  <h6>Address</h6>
                </div>
                <div class="col-md-8 mb-2">
                  <div class="row g-2">
                    <div class="col-md-12 mb-2">
                      <BaseInput
                        id="address"
                        v-model="Manufacturer.address"
                        label="Street address"
                      />
                    </div>
                    <div class="col-md-12 mb-2">
                      <BaseInput
                        id="city"
                        v-model="Manufacturer.city"
                        label="City"
                      />
                    </div>

                    <div class="col-md-6 mb-2">
                      <div class="form-group">
                        <label for="country" class="form-label">Country </label>
                        <select
                          id="country"
                          v-model="Manufacturer.country"
                          @change="GetStates"
                          class="form-control form-control-lg select2"
                          data-placeholder="Select country"
                          data-allow-clear="true"
                        >
                          <option
                            v-for="country in Settings._COUNTRIES"
                            :key="country.country_code"
                            :value="country.country_code"
                          >
                            {{ country.country_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 mb-2">
                      <div class="form-group">
                        <label for="state" class="form-label">State </label>
                        <select
                          id="state"
                          v-model="Manufacturer.state"
                          class="form-control form-control-lg select2"
                          data-placeholder="Select state"
                          data-allow-clear="true"
                        >
                          <option
                            v-for="state in States"
                            :key="state.state_code"
                            :value="state.state_code"
                          >
                            {{ state.state_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 mb-2">
                      <BaseInput
                        id="postcode"
                        v-model="Manufacturer.postcode"
                        label="Postal/Zip code"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-show="IsActiveView('Others')"
              class="otherInfo mt-3 w3-animate-opacity"
            >
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="website">Website</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="website"
                    v-model="Manufacturer.website"
                    type="text"
                  />
                </div>
              </div>
              <div class="row g-2">
                <div class="col-md-4">
                  <label for="internal_note">Internal note</label>
                </div>
                <div class="col-md-8 mb-3">
                  <BaseInput
                    id="internal_note"
                    v-model="Manufacturer.internal_note"
                    type="textarea"
                    class="min-height150"
                    hint="Not entered here will not be visible to your manufacturer"
                  />
                </div>
              </div>
            </div>
          </form>
        </template>
        <div v-else class="p-5 w3-center">
          <img src="/images/item-not-found.png" class="mb-4" />
          <h4>Manufacturer not found</h4>
          <router-link
            :to="{ name: 'manufacturers' }"
            class="w3-button w3-white w3-border"
          >
            <span class="">Manage Manufacturers</span>
          </router-link>
        </div>
      </template>
    </div>
  </BasePageContent>
</template>
<script>
import { computed, onMounted, ref, toRef } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { v4 as uuidv4 } from "uuid";
import { frontendUrl, Util } from "@/helpers/utilities";
import { loadScript } from "vue-plugin-load-script";

export default {
  components: {},
  props: {
    id: {
      type: String,
    },
    uuid: {
      type: String,
    },
    manufacturer: {
      type: Object,
    },
    tabbed: {
      type: Boolean,
      default: false,
    },
    cancelText: {
      type: String,
      default: "Back",
    },
  },
  emits: ["oncancel", "onsave"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const ID = props.id ? props.id : uuidv4();
    const Tabbed = toRef(props, "tabbed");
    const Settings = computed(() => store.getters.settings);
    const $ = window.$;
    const publicPath = frontendUrl;
    const fetching = ref(true);
    const fields = {
      uuid: "",
      user_uuid: "",
      manufacturer_name: "",
      firstname: "",
      lastname: "",
      email: "",
      mobile: "",
      telephone: "",
      fax: "",
      website: "",
      internal_note: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postcode: "",
      manufacturer_photo: "",
    };
    const Manufacturer = ref({});
    const MANUFACTURER = toRef(props, "manufacturer");
    const GetManufacturer = async () => {
      Manufacturer.value = {};
      if (props.uuid) {
        const _manufacturer = await store.dispatch(
          "manufacturers/getManufacturer",
          props.uuid
        );
        Manufacturer.value = {
          ..._manufacturer,
        };
      } else if (MANUFACTURER.value) {
        Manufacturer.value = MANUFACTURER.value;
      } else {
        Manufacturer.value = fields;
      }
      fetching.value = false;
    };
    GetManufacturer();
    const SaveManufacturer = async () => {
      const response = await store.dispatch(
        "manufacturers/updateManufacturer",
        Manufacturer.value
      );
      if (response?.status === "success") {
        if (props.uuid) {
          router.push({
            name: "manufacturer-detail",
            params: { uuid: props.uuid },
          });
        } else {
          Manufacturer.value = { ...fields };
          emit("onsave", Manufacturer.value);
        }
      }
    };
    const OnCancel = () => {
      if (props.uuid) {
        router.push({
          name: "manufacturer-detail",
          params: { uuid: props.uuid },
        });
      } else {
        Manufacturer.value = { ...fields };
        emit("oncancel");
      }
    };
    // TAB VIEWS
    const Views = ["Contact", "Address", "Others"];
    const ViewsIcons = {
      Contact: "fas fa-user-tie",
      Address: "fas fa-credit-card",
      Others: "fas fa-info-circle",
    };
    const ViewsNames = {
      Contact: "Contact",
      Address: "Address",
      Others: "Others Info",
    };
    const ActiveView = ref(Views[0]);
    const IsActiveView = (view) => {
      if (Tabbed.value) {
        return ActiveView.value === view ? true : false;
      }
      return true;
    };
    const Validate = (view) => {
      console.log(view);
      return true;
    };
    const SwitchView = (view) => {
      const newViewIndex = Views.indexOf(view);
      if (ActiveView.value === Views[newViewIndex]) return;
      if (Validate(ActiveView.value)) ActiveView.value = Views[newViewIndex];
    };
    const States = computed(() => store.state.states);
    const GetStates = () => {
      store.dispatch("states", {
        country: Manufacturer.value.country,
        state_field: "states",
      });
    };
    GetStates();
    onMounted(() => {
      loadScript(publicPath + "/vendor/jquery/jquery-3.6.0.min.js");
      loadScript(publicPath + "/vendor/select2/js/select2.full.min.js")
        .then(() => {
          const $country = $("#country").select2({
            theme: "bootstrap",
          });
          const $state = $("#state").select2({
            theme: "bootstrap",
          });
          $country
            .on("select2:select", function (e) {
              var data = e.params.data;
              Manufacturer.value.country = data.id;
              GetStates();
            })
            .on("select2:unselect", function () {
              store.dispatch("states", {
                country: Manufacturer.value.country,
              });
              $country.val(null).trigger("change");
              $state.val(null).trigger("change");
              Manufacturer.value.country = "";
              Manufacturer.value.state = "";
            });
          $state
            .on("select2:select", function (e) {
              var data = e.params.data;
              Manufacturer.value.state = data.id;
            })
            .on("select2:unselect", function () {
              $state.val(null).trigger("change");
              Manufacturer.value.state = "";
            });
        })
        .catch((err) => {
          console.log(err);
        });
    });
    return {
      store,
      Settings,
      fetching,
      States,
      GetStates,
      ID,
      Tabbed,
      Manufacturer,
      SaveManufacturer,
      OnCancel,
      Views,
      ViewsIcons,
      ViewsNames,
      IsActiveView,
      ActiveView,
      SwitchView,
      Util,
    };
  },
};
</script>
<template>
  <BasePageContent>
    <BasePageHeader
      class="px-3"
      title="Manufacturers"
      description="Manage your manufacturers"
    >
      <ul class="nk-block-tools g-1 pr-3">
        <li class="nk-block-tools-opt">
          <a
            href="#"
            title="Add new manufacturer"
            class="btn btn-white btn-dim btn-outline-light"
            @click.prevent="Toggle = true"
          >
            <em class="dd-indc icon ni ni-plus"></em>
            <span class="d-inline">New manufacturer</span>
            <em class="dd-indc w3-text-theme icon ni ni-chevron-right"></em>
          </a>
          <AddManufacturer
            :toggle-by="Toggle"
            @oncancel="SaveOnNew"
            @onsave="SaveOnNew"
            title="Add new manufacturer"
          ></AddManufacturer>
        </li>
      </ul>
    </BasePageHeader>
    <div class="card-inner">
      <div class="px-1">
        <ManufacturerList
          id="manufacturerList"
          :manufacturers="Manufacturers"
        />
      </div>
    </div>
  </BasePageContent>
</template>
<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import ManufacturerList from "@/components/ui/manufacturer/ManufacturerList.vue";
import AddManufacturer from "@/components/ui/manufacturer/AddManufacturer.vue";
import { Util } from "@/helpers/utilities";

import { useStore } from "vuex";
export default {
  components: {
    ManufacturerList,
    AddManufacturer,
  },
  props: {
    addNew: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();
    const title = ref("Manufacturers");
    const Manufacturers = computed(
      () => store.getters["manufacturers/manufacturers"]
    );
    const Toggle = ref(props.addNew);
    const router = useRouter();
    const SaveOnNew = () => {
      if (props.addNew) {
        router.push({
          name: "manufacturers",
        });
      } else {
        Toggle.value = false;
      }
    };
    return {
      title,
      Util,
      Manufacturers,
      Toggle,
      SaveOnNew,
    };
  },
};
</script>
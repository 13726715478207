<template>
  <template v-if="!Util.isEmpty(Manufacturers)">
    <DataTable :id="ID" class="table-hover table-lg" :options="Options">
      <thead>
        <tr class="nk-tb-item nk-tb-head">
          <th class="nk-tb-col nk-tb-col-check">
            <div
              class="custom-control custom-control-sm custom-checkbox notext"
            >
              <input type="checkbox" class="custom-control-input" id="uid" />
              <label class="custom-control-label" for="uid"></label>
            </div>
          </th>
          <th class="nk-tb-col" style="min-width: 200px">Manufacturer name</th>
          <!-- <th class="nk-tb-col tb-col-mb">Payables</th> -->
          <th class="nk-tb-col tb-col-lg">Telephone</th>
          <th class="nk-tb-col tb-col-md">Email</th>
          <th class="nk-tb-col nk-tb-col-tools text-right"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="nk-tb-item"
          v-for="manufacturer in Manufacturers"
          :key="manufacturer.uuid"
        >
          <td class="nk-tb-col nk-tb-col-check">
            <div
              class="custom-control custom-control-sm custom-checkbox notext"
            >
              <input type="checkbox" class="custom-control-input" id="uid1" />
              <label class="custom-control-label" for="uid1"></label>
            </div>
          </td>
          <td class="nk-tb-col">
            <router-link
              :to="{
                name: 'manufacturer-detail',
                params: { uuid: manufacturer.uuid },
              }"
              class="cursor"
            >
              <div class="size15 nowrap ellipsis">
                {{ manufacturer.manufacturer_name + " " }}
              </div>
              <div
                v-if="manufacturer.fullname"
                class="size12 w3-text-grey ellipsis"
              >
                <span class="d-none"> (</span>
                {{ manufacturer.fullname }}
                <span class="d-none">)</span>
              </div>
            </router-link>
          </td>
          <!-- <td class="nk-tb-col tb-col-md" v-html="Util.Currency('0.00')"></td> -->
          <td class="nk-tb-col tb-col-lg ellipsis">
            <a
              v-if="manufacturer.telephone"
              :href="'tel:' + manufacturer.email"
              target="_blank"
              class="d-block"
              >{{ manufacturer.telephone }}
            </a>
          </td>
          <td class="nk-tb-col tb-col-md ellipsis">
            <a
              v-if="manufacturer.email"
              :href="'mailto:' + manufacturer.email"
              target="_blank"
              >{{ manufacturer.email }}
            </a>
          </td>
          <td class="nk-tb-col nk-tb-col-tools">
            <ul class="nk-tb-actions gx-1">
              <li>
                <div class="drodown">
                  <a
                    href="#"
                    class="dropdown-toggle btn btn-icon btn-trigger"
                    data-toggle="dropdown"
                    ><em class="icon ni ni-more-h"></em>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right">
                    <ul class="link-list-opt no-bdr">
                      <!-- <li>
                        <a
                          title="Create New Bill"
                          :data-uuid="manufacturer.uuid"
                          href="#"
                        >
                          <em class="icon ni ni-file"></em>
                          <span>New Bill</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Create New Purchase Order"
                          :data-uuid="manufacturer.uuid"
                          href="#"
                        >
                          <em class="icon fas fa-file-alt"></em>
                          <span>New Purchase Order</span></a
                        >
                      </li>

                      <li class="divider"></li> -->
                      <li>
                        <a
                          title="Email Manufacturer"
                          :data-uuid="manufacturer.uuid"
                          @click.prevent="DeleteDetail(manufacturer.uuid)"
                          href="#"
                        >
                          <em class="icon ni ni-mail-fill"></em>
                          <span>Email Manufacturer</span></a
                        >
                      </li>
                      <li>
                        <router-link
                          title="Manufacturer Detail"
                          :to="{
                            name: 'manufacturer-detail',
                            params: { uuid: manufacturer.uuid },
                          }"
                          class="cursor"
                        >
                          <em class="icon ni ni-eye"></em>
                          <span>Manufacturer Detail</span></router-link
                        >
                      </li>
                      <li>
                        <a
                          title="Edit"
                          :data-uuid="manufacturer.uuid"
                          @click.prevent="QuickEdit(manufacturer.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-edit"></em>
                          <span>Edit Manufacturer</span></a
                        >
                      </li>
                      <li>
                        <a
                          title="Edit"
                          :data-uuid="manufacturer.uuid"
                          @click.prevent="DeleteDetail(manufacturer.uuid)"
                          href="#"
                        >
                          <em class="icon fas fa-trash"></em>
                          <span>Delete Manufacturer</span></a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </DataTable>
  </template>
  <div v-else class="p-5 w3-center">
    <img src="/images/item-not-found.png" class="mb-4" />
    <h4>No Item was found</h4>
    <a
      href="#"
      title="Add new manufacturer"
      class="btn btn-white btn-dim btn-outline-light"
      @click.prevent="addManufacturer = true"
    >
      <em class="icon ni ni-edit"></em>
      <span class="d-none d-md-inline">Add a new manufacturer</span>
    </a>
  </div>
  <AddManufacturer
    :toggle-by="addManufacturer"
    @oncancel="addManufacturer = false"
    @onsave="addManufacturer = false"
    title="Add a new manufacturer"
  ></AddManufacturer>
  <Aside
    :toggle-by="DoEdit"
    @oncancel="OnCancel"
    cancel-text="Cancel"
    width="1024px"
  >
    <ManufacturerUpdate
      tabbed
      v-if="DoEdit"
      :manufacturer="Manufacturer"
      @onsave="OnSave"
      @oncancel="OnCancel"
    />
  </Aside>
</template>
<script>
import {
  // computed,
  ref,
  toRef,
} from "vue";
import { useStore } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { Util } from "@/helpers/utilities";
import DataTable from "@/components/ui/DataTable.vue";
import ManufacturerUpdate from "@/components/ui/manufacturer/ManufacturerUpdate.vue";
import AddManufacturer from "@/components/ui/manufacturer/AddManufacturer.vue";
import Aside from "@/components/ui/Aside.vue";
export default {
  components: {
    DataTable,
    AddManufacturer,
    ManufacturerUpdate,
    Aside,
  },
  props: {
    id: {
      type: String,
    },
    manufacturers: {
      type: Array,
    },
  },

  setup(props) {
    const store = useStore();
    const ID = props.id ? props.id : uuidv4();
    const Manufacturers = toRef(props, "manufacturers");
    const addManufacturer = ref(false);
    const Options = {
      pageLength: 25,
      responsive: false,
      order: [[0, "asc"]],
      aoColumnDefs: [
        {
          bSortable: false,
          aTargets: [0],
        },
      ],
    };

    const DeleteDetail = (uuid) => {
      console.log("DeleteDetail", uuid);
    };
    const Manufacturer = ref({});
    const DoEdit = ref(false);
    const QuickEdit = async (uuid) => {
      const _manufacturer = await store.dispatch(
        "manufacturers/getManufacturer",
        uuid
      );
      Manufacturer.value = {
        ..._manufacturer,
      };
      DoEdit.value = true;
    };
    const OnCancel = () => {
      Manufacturer.value = {};
      DoEdit.value = false;
    };
    const OnSave = () => {
      OnCancel();
    };
    return {
      store,
      ID,
      Manufacturer,
      Manufacturers,
      addManufacturer,
      QuickEdit,
      Util,
      Options,
      OnSave,
      DeleteDetail,
      DoEdit,
      OnCancel,
    };
  },
};
</script>